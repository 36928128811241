export function loginOutClear() {
    window.localStorage.removeItem('token')
    localStorage.removeItem('tabsViewList')
    localStorage.removeItem('menuRootId')
    localStorage.removeItem('userId')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('headMenuList')
    localStorage.removeItem('headMenuListStr')
    localStorage.removeItem('loginInfo')
}

export function loginSuccessSet(res) {
    window.localStorage.setItem('token', res.data.access_token)
    localStorage.setItem('userId', res.data.userId)
    localStorage.setItem('userInfo', JSON.stringify(res.data))
}


function buZero(v) {
    return v < 10 ? '0' + v : '' + v
}

export function getDateObject(v) {
    let objDate = null
    if (v) {
        objDate = new Date(v)
    } else {
        objDate = new Date()
    }

    let y = objDate.getFullYear()
    let m = objDate.getMonth()
    let d = objDate.getDate()

    let h = objDate.getHours()
    let mi = objDate.getMinutes()
    let s = objDate.getSeconds()
    let obj = {
        y: y + '',
        m: buZero(m + 1),
        d: buZero(d),
        h: buZero(h),
        mi: buZero(mi),
        s: buZero(s),
        stamp: objDate.getTime()
    }

    return  {
        ...obj,
        time: obj.y + '-' + obj.m + '-' + obj.d + ' ' + obj.h + ':' + obj.mi + ':' + obj.s
    }
}

export function downloadBlob(res, fileName) {
    const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
    const a = document.createElement('a');
    const href = window.URL.createObjectURL(blob); // 创建下载连接
    a.href = href;
    a.download = decodeURI(fileName); // 文件名
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a); // 下载完移除元素
    window.URL.revokeObjectURL(href); // 释放掉blob对象
}

export function downloadBlobZip(res, fileName) {
    const blob = new Blob([res], { type: 'application/zip' });
    const a = document.createElement('a');
    const href = window.URL.createObjectURL(blob); // 创建下载连接
    a.href = href;
    a.download = decodeURI(fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a); // 下载完移除元素
    window.URL.revokeObjectURL(href); // 释放掉blob对象
}


export function downloadBlobImg(dataurl, fileName) {
    let arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    var myBlob = new Blob([u8arr], { type: mime });
    var url = URL.createObjectURL(myBlob); // 创建图片的临时url
    var a = document.createElement("a") // 新建一个a链接
    a.setAttribute("href", url) // a链接的url为图片的url
    a.setAttribute("download", fileName)
    a.setAttribute("target","_blank")
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a); // 下载完移除元素
    window.URL.revokeObjectURL(url); // 释放掉blob对象
}

export function isValidHttpUrl(string) {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}
<template>
    <!-- <el-radio-group v-model="modelValue" @change="handleChange">
        <el-radio v-for="item in statusOptions" :key="item.value" :label="item.value">
            {{item.name}}
        </el-radio>
    </el-radio-group>-->
    <div class="status-cons">
        <el-switch
            v-model="modelValue"
            @change="handleChange"
            :active-value="1"
            :inactive-value="0"
        >
        </el-switch>
        <span class="color-main ml15" v-if="modelValue === 1">已启用</span>
        <span class="ml15" v-if="modelValue === 0">禁用</span>
    </div>

</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        isAllItem: {
            type: Boolean,
            default: true
        }
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    data() {
        return {
            statusOptions: [{
                value: 1,
                name: '启用'
            }, {
                value: 0,
                name: '禁用'
            }]
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    mounted() {
        /*if (this.statusOptions.length === 0) {
            this.getStatusOptions()
        }*/
    },
    methods: {
        ...mapActions({
            getStatusOptions: 'getStatusOptions'
        }),
        handleChange(v) {
            this.$emit('input', v)
        }
    }
}
</script>
<style scoped lang="less">
    .status-cons {
        display: flex;
        align-items: center;
    }
</style>
import {DictApi, RoleApi } from "@/apis/index";

export default {
    state: {
        statusOptions: [],
        regionOptions: [], // 区域api
        dataTypeOptions: [], // 数据类型
        userTypeOptions: [], // 用户类型
        sexOptions: [], // 性别
        roleOptions: [],
        attestationTypeOptions: [{
            name: '未认证',
            value: 0
        }, {
            name: '已认证',
            value: 1
        }, {
            name: '认证中',
            value: 2
        }, {
            name: '认证失败',
            value: 3
        }],
        threeServiceTypeOptions: [], // 三务公开类型
        deptTypeOptions: [{
            name: '农业型',
            value: 0
        }], // 部门业务线条类别
        politicalOutlookOptions: [],
        organizationTypeOptions: [],
        householdersRelationOptions: [], // 与户主关系
        nationOptions: [], // 民族
        residentPersonTypeOptions: [], // 人员类别

        appThemeTypeOptions: [], // 应用主体类型
        sourceTypeOptions: [],
        auditAdviceOptions: [],
        photographNodeOptions: [],
        score_type_appOptions: [],
        score_apply_statusOptions: [],
        compOrganizationTypeOptions: [],
        companyTypeOptions: [],
        cooperateStatusOptions: [],
        offlineTypeOptions: [],
        scoreTypeOptions: [],
        orderStatusOptions: [],
        orderTypeOptions: [],
        deliveryTypeOptions: [],
        shopDeliveryTypeEnumOptions: [],
        ruleLocationOptions: [],
        reviewerResultOptions: [],
        familyTypeOptions: [],
        familyLevelOptions: [],
        smsSenceDefineOptions: []
    },
    getters: {

    },
    mutations: {
        set_statusOptions(state, v) {
            state.statusOptions = v
        },
        get_statusName(state, v) {
            let str = ''
            let arr = state.statusOptions.filter((ev) => {
                return ev.value === v
            })
            str = arr[0] ? arr[0].name : ''
            return str
        },

        set_dataTypeOptions(state, v) {
            state.dataTypeOptions = v
        },
        set_userTypeOptions(state, v) {
            state.userTypeOptions = v
        },
        set_sexOptions(state, v) {
            state.sexOptions = v
        },
        set_roleOptions(state, v) {
            state.roleOptions = v
        },
        set_threeServiceTypeOptions(state, v) {
            state.threeServiceTypeOptions = v
        },
        set_politicalOutlookOptions(state, v) {
            state.politicalOutlookOptions = v
        },
        set_organizationTypeOptions(state, v) {
            state.organizationTypeOptions = v
        },
        set_householdersRelationOptions(state, v) {
            state.householdersRelationOptions = v
        },
        set_nationOptions(state, v) {
            state.nationOptions = v
        },
        set_residentPersonTypeOptions(state, v) {
            state.residentPersonTypeOptions = v
        },
        set_appThemeTypeOptions(state, v) {
            state.appThemeTypeOptions = v
        },
        set_sourceTypeOptions(state, v) {
            state.sourceTypeOptions = v
        },
        set_auditAdviceOptions(state, v) {
            state.auditAdviceOptions = v
        },
        set_photographNodeOptions(state, v) {
            state.photographNodeOptions = v
        },
        set_score_type_appOptions(state, v) {
            state.score_type_appOptions = v
        },
        set_score_apply_statusOptions(state, v) {
            state.score_apply_statusOptions = v
        },
        set_compOrganizationTypeOptions(state, v) {
            state.compOrganizationTypeOptions = v
        },
        set_companyTypeOptions(state, v) {
            state.companyTypeOptions = v
        },
        set_cooperateStatusOptions(state, v) {
            state.cooperateStatusOptions = v
        },
        set_offlineTypeOptions(state, v) {
            state.offlineTypeOptions = v
        },
        set_scoreTypeOptions(state, v) {
            state.scoreTypeOptions = v
        },
        set_orderStatusOptions(state, v) {
            state.orderStatusOptions = v
        },
        set_orderTypeOptions(state, v) {
            state.orderTypeOptions = v
        },
        set_deliveryTypeOptions(state, v) {
            state.deliveryTypeOptions = v
        },
        set_shopDeliveryTypeEnumOptions(state, v) {
            state.shopDeliveryTypeEnumOptions = v
        },
        set_ruleLocationOptions(state, v) {
            state.ruleLocationOptions = v
        },
        set_reviewerResultOptions(state, v) {
            state.reviewerResultOptions = v
        },
        set_familyTypeOptions(state, v) {
            state.familyTypeOptions = v
        },
        set_familyLevelOptions(state, v) {
            state.familyLevelOptions = v
        },
        set_smsSenceDefineOptions(state, v) {
            state.smsSenceDefineOptions = v
        },
    },
    actions: {
        getStatusOptions({ commit }) {
            let paramsObj = {
                pcode: 'status',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_statusOptions', [
                        ...list
                    ])
                }
            })
        },
        getDataTypeOptions({ commit }) {
            let paramsObj = {
                pcode: 'dataType',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_dataTypeOptions', [
                        ...list
                    ])
                }
            })
        },
        getUserTypeOptions({ commit }) {
            let paramsObj = {
                pcode: 'userType',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_userTypeOptions', [
                        ...list
                    ])
                }
            })
        },
        getSexOptions({ commit }) {
            let paramsObj = {
                pcode: 'gender',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_sexOptions', [
                        ...list
                    ])
                }
            })
        },
        getRoleOptions({ commit }) {
            /*let paramsObj = {}
            UserApi.roleSelect(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_roleOptions', [
                        ...list
                    ])
                }
            })*/

            let paramsObj = {
                page: {
                    currentPage: 1,
                    pageSize: 100
                }
            }
            RoleApi.list(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data.list || []
                    commit('set_roleOptions', [
                        ...list
                    ])
                }
            })
        },
        getThreeServiceTypeOptions({ commit }) {
            let paramsObj = {
                pcode: 'threeServiceType',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_threeServiceTypeOptions', [
                        ...list
                    ])
                }
            })
        },
        getPoliticalOutlookOptions({ commit }) {
            let paramsObj = {
                pcode: 'politicalOutlook',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_politicalOutlookOptions', [
                        ...list
                    ])
                }
            })
        },
        getOrganizationTypeOptions({ commit }) {
            let paramsObj = {
                pcode: 'organizationType',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_organizationTypeOptions', [
                        ...list
                    ])
                }
            })
        },
        getHouseholdersRelationOptions({ commit }) {
            let paramsObj = {
                pcode: 'householdersRelation',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_householdersRelationOptions', [
                        ...list
                    ])
                }
            })
        },
        getNationOptions({ commit }) {
            let paramsObj = {
                pcode: 'nation',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_nationOptions', [
                        ...list
                    ])
                }
            })
        },
        getResidentPersonTypeOptions({ commit }) {
            let paramsObj = {
                pcode: 'residentPersonType',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_residentPersonTypeOptions', [
                        ...list
                    ])
                }
            })
        },
        getAppThemeTypeOptions({ commit }) {
            let paramsObj = {
                pcode: 'appThemeType',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_appThemeTypeOptions', [
                        ...list
                    ])
                }
            })
        },
        getSourceTypeOptions({ commit }) {
            let paramsObj = {
                pcode: 'sourceType',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_sourceTypeOptions', [
                        ...list
                    ])
                }
            })
        },
        getAuditAdviceOptions({ commit }) {
            let paramsObj = {
                pcode: 'auditAdvice',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_auditAdviceOptions', [
                        ...list
                    ])
                }
            })
        },
        getPhotographNodeOptions({ commit }) {
            let paramsObj = {
                pcode: 'photographNode',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_photographNodeOptions', [
                        ...list
                    ])
                }
            })
        },
        getScore_type_appOptions({ commit }) {
            let paramsObj = {
                pcode: 'score_type_app',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_score_type_appOptions', [
                        ...list
                    ])
                }
            })
        },
        getScore_apply_statusOptions({ commit }) {
            let paramsObj = {
                pcode: 'score_apply_status',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_score_apply_statusOptions', [
                        ...list
                    ])
                }
            })
        },
        getCompOrganizationTypeOptions({ commit }) {
            let paramsObj = {
                pcode: 'compOrganizationType',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_compOrganizationTypeOptions', [
                        ...list
                    ])
                }
            })
        },
        getCompanyTypeOptions({ commit }) {
            let paramsObj = {
                pcode: 'companyType',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_companyTypeOptions', [
                        ...list
                    ])
                }
            })
        },
        getCooperateStatusOptions({ commit }) {
            let paramsObj = {
                pcode: 'cooperateStatus',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_cooperateStatusOptions', [
                        ...list
                    ])
                }
            })
        },
        getOfflineTypeOptions({ commit }) {
            let paramsObj = {
                pcode: 'offlineType',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_offlineTypeOptions', [
                        ...list
                    ])
                }
            })
        },
        getScoreTypeOptions({ commit }) {
            let paramsObj = {
                pcode: 'score_type',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_scoreTypeOptions', [
                        ...list
                    ])
                }
            })
        },
        getOrderStatusOptions({ commit }) {
            let paramsObj = {
                pcode: 'orderStatus',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_orderStatusOptions', [
                        ...list
                    ])
                }
            })
        },
        getOrderTypeOptions({ commit }) {
            let paramsObj = {
                pcode: 'order_type',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_orderTypeOptions', [
                        ...list
                    ])
                }
            })
        },
        getDeliveryTypeOptions({ commit }) {
            let paramsObj = {
                pcode: 'delivery_type',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_deliveryTypeOptions', [
                        ...list
                    ])
                }
            })
        },
        getShopDeliveryTypeEnumOptions({ commit }) {
            let paramsObj = {
                pcode: 'ShopDeliveryTypeEnum',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_shopDeliveryTypeEnumOptions', [
                        ...list
                    ])
                }
            })
        },
        getRuleLocationOptions({ commit }) {
            let paramsObj = {
                pcode: 'ruleLocation',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_ruleLocationOptions', [
                        ...list
                    ])
                }
            })
        },
        getReviewerResultOptions({ commit }) {
            let paramsObj = {
                pcode: 'reviewer_result',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_reviewerResultOptions', [
                        ...list
                    ])
                }
            })
        },
        getFamilyTypeOptions({ commit }) {
            let paramsObj = {
                pcode: 'familyType',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_familyTypeOptions', [
                        ...list
                    ])
                }
            })
        },
        getFamilyLevelOptions({ commit }) {
            let paramsObj = {
                pcode: 'familyLevel',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_familyLevelOptions', [
                        ...list
                    ])
                }
            })
        },
        getSmsSenceDefineOptions({ commit }) {
            let paramsObj = {
                pcode: 'SmsSenceDefine',
                inValues: ''
            }
            DictApi.dtCache(paramsObj).then((res) => {
                if (res.code === 0) {
                    let list = res.data || []
                    commit('set_smsSenceDefineOptions', [
                        ...list
                    ])
                }
            })
        },
    },
}
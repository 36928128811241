
let hostUrl = location.protocol === 'https:' ? location.origin + '/' : 'https://test.sccpapi.ah-esage.cn/'

export default {
    // 图片地址
    imgUrl: process.env.NODE_ENV === "development" ? 'https://test.sccpapi.ah-esage.cn/' : '/',

    // 登录页 验证码地址
    codeUrl: process.env.NODE_ENV === "development" ? 'https://test.sccpapi.ah-esage.cn/' : '/',

    // 接口地址
    interfaceUrl: process.env.NODE_ENV === "development" ? '' : '',

    // 富文本编辑器地址
    richTextUrl: process.env.NODE_ENV === "development" ? 'https://test.sccpapi.ah-esage.cn/' : hostUrl,

    // 推送小程序版本
    miniprogramState: 'formal' // developer 为开发板   trial 为体验版     formal 为正式版

}
import {
    Button,
    Pagination,
    Dialog,
    Menu,
    Submenu,
    MenuItem,
    MenuItemGroup,
    Input,
    InputNumber,
    Radio,
    RadioGroup,
    RadioButton,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    Switch,
    Select,
    Option,
    OptionGroup,
    Form,
    FormItem,
    Table,
    TableColumn,
    Tree,
    Tag,
    Row,
    Col,
    DatePicker,
    Tooltip,
    Popover,
    Steps,
    Step,
    Empty,
    TimePicker,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Loading,
    MessageBox,
    Message,
    Progress,
    Notification
} from 'element-ui';

export default {
    install: (vue) => {
        vue.use(Button);
        vue.use(Pagination);
        vue.use(Dialog);
        vue.use(Menu);
        vue.use(Submenu);
        vue.use(MenuItem);
        vue.use(MenuItemGroup);
        vue.use(Input);
        vue.use(InputNumber);
        vue.use(Radio);
        vue.use(RadioGroup);
        vue.use(RadioButton);
        vue.use(Checkbox);
        vue.use(CheckboxButton);
        vue.use(CheckboxGroup);
        vue.use(Switch);
        vue.use(Select);
        vue.use(Option);
        vue.use(OptionGroup);
        vue.use(Form);
        vue.use(FormItem);
        vue.use(Table);
        vue.use(TableColumn);
        vue.use(Tree);
        vue.use(Row);
        vue.use(Col);
        vue.use(DatePicker)
        vue.use(Tooltip)
        vue.use(Popover)
        vue.use(Steps)
        vue.use(Step)
        vue.use(Empty)
        vue.use(TimePicker)
        vue.use(Progress)
        vue.use(Dropdown);
        vue.use(DropdownMenu);
        vue.use(DropdownItem);
        vue.use(Tag)

        vue.use(Loading.directive);

        vue.prototype.$loading = Loading.service;
        vue.prototype.$msgbox = MessageBox;
        vue.prototype.$alert = MessageBox.alert;
        vue.prototype.$confirm = MessageBox.confirm;
        vue.prototype.$prompt = MessageBox.prompt;
        vue.prototype.$notify = Notification;
        vue.prototype.$message = Message;
    }
}
<template>
    <div class="el-select width-full" :class="{ 'is-disabled': disabled }" @click="onClick">
        <div class="el-input el-input--suffix">
            <input type="text"
                   :value="value"
                   readonly="readonly"
                   autocomplete="off"
                   :placeholder="placeholder"
                   class="el-input__inner"
                   :class="{ shortInput: shortInput }">
            <span class="el-input__suffix">
                <span class="el-input__suffix-inner">
                    <i class="el-select__caret el-input__icon el-icon-arrow-up" :class="{ 'is-reverse': isReverse}"></i>
                </span>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        shortInput: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            isReverse: this.isOpen
        }
    },
    methods: {
        onClick() {
            if (this.disabled) {

            } else {
                this.isReverse = !this.isReverse
                this.$emit('click', '')
            }
        }
    }
}
</script>
<style lang="less" scoped>
 // is-reverse

 .shortInput {
     height: 38px !important;
     line-height: 38px !important;
 }

 .el-select.is-disabled .el-input__inner {
     background-color: #F5F7FA;
     border-color: #E4E7ED;
     color: #C0C4CC;
     cursor: not-allowed;
 }
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/style.less'
import 'viewerjs/dist/viewer.css'

import VueViewer from 'v-viewer'

import elementCom from "@/plugins/elementCom"
import global from "@/common/global";

Vue.use(elementCom)
Vue.use(global)
Vue.use(VueViewer, {
  defaultOptions: {
    zIndex: 9999
  }
})

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

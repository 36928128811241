import Vue from 'vue'
import Vuex from 'vuex'
import dic from './dic'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userId: '',
        userRegionId: '',
        permissionList: [],
        ...dic.state
    },
    getters: {
        ...dic.getters
    },
    mutations: {
        set_userId(state, v) {
            this.state.userId = v
        },
        set_userRegionId(state, v) {
            state.userRegionId = v
        },
        set_permissionList(state, v) {
            state.permissionList = v
        },
        ...dic.mutations
    },
    actions: {
        ...dic.actions
    },
    modules: {
    }
})

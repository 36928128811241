<template>
    <el-select v-model="modelValue" placeholder="请选择状态" @change="handleChange">
        <el-option v-if="isAllItem" label="全部" :value="''"></el-option>
        <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value">
        </el-option>
    </el-select>
</template>
<script>
    import { mapState, mapActions } from 'vuex'
    export default {
        props: {
            value: {
                type: [String, Number],
                default: ''
            },
            isAllItem: {
                type: Boolean,
                default: true
            }
        },
        model: {
            prop: 'value',
            event: 'input'
        },
        data() {
            return {
                statusOptions: [{
                    value: 1,
                    name: '启用'
                }, {
                    value: 0,
                    name: '禁用'
                }]
            }
        },
        computed: {
            /*...mapState({
                statusOptions: 'statusOptions'
            }),*/
            modelValue: {
                get(v) {
                    return this.value
                },
                set(v) {
                    this.$emit('input', v)
                }
            }
        },
        mounted() {
            /*if (this.statusOptions.length === 0) {
                this.getStatusOptions()
            }*/
        },
        methods: {
            ...mapActions({
                getStatusOptions: 'getStatusOptions'
            }),
            handleChange(v) {
                this.$emit('input', v)
            }
        }
    }
</script>
<style scoped lang="less">

</style>
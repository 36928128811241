import { CommonApi } from '@/apis/index'
import { Message }  from 'element-ui';
import globalConfig from "@/common/globalConfig";
let globalUrl = globalConfig.richTextUrl
export default {
    language:'zh_CN',
    height: 600,
    menubar: false,
    branding: false, // 隐藏右下角技术支持
    contextmenu_never_use_native: false, // 在编辑器中屏蔽浏览器本身的右键菜单
    convert_urls: false, // 图片带域名
    fontsize_formats: '11px 12px 14px 16px 18px 24px 36px 48px',
    content_style: "img {max-width:100%;}",
    plugins: 'image fullscreen link emoticons hr insertdatetime lists nonbreaking noneditable pagebreak paste print searchreplace table visualblocks',
    toolbar: 'undo redo emoticons bold forecolor backcolor italic  underline strikethrough alignleft aligncenter alignright  alignjustify ' +
        'formatselect fontselect  fontsizeselect  insertdatetime hr cut copy paste  bullist numlist outdent indent blockquote  ' +
        'removeformat numlist bullist pagebreak  nonbreaking subscript superscript  customImage customVideo customLink fullscreen  print searchreplace ' +
        'table  visualblocks',
    // 初始化前执行
    setup: function (editor) {
        // 添加按钮
        editor.ui.registry.addButton('customImage', {
            icon: 'image',
            tooltip: '上传图片',
            onAction: function (ev) {
                let haveFileInput = document.getElementById('fileInput')
                if (haveFileInput) {
                    document.body.removeChild(haveFileInput)
                }
                const fileInput = document.createElement('input');
                fileInput.style.display = 'none';
                fileInput.type = 'file';
                fileInput.id = 'fileInput'
                fileInput.click();
                fileInput.onchange = function (ev) {
                    let files = ev.target.files[0]
                    let typeArr = files.type.split('/')
                    let typeStr = typeArr[typeArr.length - 1]
                    let accept = ['jgp', 'jpeg', 'png', 'svg', 'pdf']
                    if (!accept.includes(typeStr)) {
                        let typeAccept = accept.join(',')
                        document.body.removeChild(fileInput)
                        Message.error('该文件格式不支持，只支持 ' + typeAccept + ' 文件格式')
                        return
                    }
                    let fileData = new FormData()
                    fileData.append('file', files)
                    let obj = {
                        params: {
                            bucketName: 'base',
                            folderName: 'sccpweb'
                        },
                        formData: fileData
                    }
                    CommonApi.upload(obj).then((res) => {
                        this.loading = false
                        if (res.code === 0) {
                            let url = globalUrl + res.data || ''
                            let imgStr = `<img class="customer-img-rich-text" src="${url}">`
                            editor.insertContent(imgStr)
                        }
                    })
                    document.body.removeChild(fileInput)
                }
                document.body.appendChild(fileInput);
            }
        });
        // 添加按钮
        editor.ui.registry.addButton('customVideo', {
            icon: 'embed',
            tooltip: '上传视频',
            onAction: function (ev) {
                let haveFileInput = document.getElementById('fileInput')
                if (haveFileInput) {
                    document.body.removeChild(haveFileInput)
                }
                const fileInput = document.createElement('input');
                fileInput.style.display = 'none';
                fileInput.type = 'file';
                fileInput.id = 'fileInput'
                fileInput.click();
                fileInput.onchange = function (ev) {
                    let files = ev.target.files[0]
                    let typeArr = files.type.split('/')
                    let typeStr = typeArr[typeArr.length - 1]
                    let accept = ['mp4', 'mov', 'mpg','MXF','ts', 'flv', 'WMV','AVI','M4V','F4V','MPEG','3GP','ASF','MKV']
                    if (!accept.includes(typeStr)) {
                        let typeAccept = accept.join(',')
                        document.body.removeChild(fileInput)
                        Message.error('该文件格式不支持，只支持 ' + typeAccept + ' 文件格式')
                        return
                    }
                    let fileData = new FormData()
                    fileData.append('file', files)
                    let obj = {
                        params: {
                            bucketName: 'base',
                            folderName: 'sccpweb'
                        },
                        formData: fileData
                    }
                    CommonApi.upload(obj).then((res) => {
                        this.loading = false
                        if (res.code === 0) {
                            let url = globalUrl + res.data || ''
                            let imgStr = `<video controls="true" class="customer-img-rich-text" style="width:100%;"><source src="${url}"></source></video>`
                            editor.insertContent(imgStr)
                        } else{
                            Message.error('视频文件过大，请重新上传')
                        }
                    })
                    document.body.removeChild(fileInput)
                }
                document.body.appendChild(fileInput);
            }
        });
        editor.ui.registry.addButton('customLink', {
            icon: 'link',
            tooltip: '上传文件',
            onAction: function (ev) {
                let haveFileInput = document.getElementById('fileInput')
                if (haveFileInput) {
                    document.body.removeChild(haveFileInput)
                }
                const fileInput = document.createElement('input');
                fileInput.style.display = 'none';
                fileInput.type = 'file';
                fileInput.id = 'fileInput'
                fileInput.click();
                fileInput.onchange = function (ev) {
                    let files = ev.target.files[0]
                    let filesName = files.name
                    let fileData = new FormData()
                    fileData.append('file', files)
                    let obj = {
                        params: {
                            bucketName: 'base',
                            folderName: 'sccpweb'
                        },
                        formData: fileData
                    }
                    CommonApi.upload(obj).then((res) => {
                        this.loading = false
                        if (res.code === 0) {
                            let url = globalUrl + res.data || ''
                            let imgStr = `<a class="customer-a-rich-text mceEditable" download href="${url}">${filesName}</a>`
                            editor.insertContent(imgStr)
                        }
                    })
                    document.body.removeChild(fileInput)
                }
                document.body.appendChild(fileInput);
            }
        });
    },

    // 初始化后执行
    init_instance_callback : (editor) => {
        // 的编辑器已初始化完成
    }
}
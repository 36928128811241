<template>
    <textarea ref="richText"></textarea>
</template>
<script>
import richTextConfig from "@/common/richTextConfig";

export  default  {
    data() {
        return {
            richTextId: ''
        }
    },
    mounted() {
        var el = this.$refs.richText
        tinymce.init({
            target: el,
            ...richTextConfig
        }).then((editors) => {
            this.richTextId = editors[0].id
            this.initBackCall()
        });
    },
    beforeDestroy() {
        tinymce.get(this.richTextId).destroy()
    },
    methods: {
        initBackCall() {
            this.$emit('initBackCall')
        },
        setContent(v) {
            setTimeout(() => {
                if (tinymce.get(this.richTextId)) {
                    tinymce.get(this.richTextId).setContent(v)
                }
            }, 500)
        },
        getContent() {
            return tinymce.get(this.richTextId).getContent()
        }
    }
}
</script>
<style>

</style>
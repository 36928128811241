const systemRouter = [
    {
        path: '/systemSet/menuList/menuList',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/sys/menu/menuList')
    },
    {
        path: '/systemSet/menu/increase',
        component: () => import('@/views/systemSet/sys/menu/increaseMenu')
    },
    {
        path: '/systemSet/streetMenu/streetMenu',
        component: () => import('@/views/systemSet/sys/streetMenu')
    },
    {
        path: '/systemSet/roleList/roleList',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/sys/role/roleList')
    },
    {
        path: '/systemSet/roleList/roleIncrease',
        component: () => import('@/views/systemSet/sys/role/roleIncrease')
    },
    {
        path: '/systemSet/userList/userList',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/sys/user/userList')
    },
    {
        path: '/systemSet/userList/increase',
        component: () => import('@/views/systemSet/sys/user/increase')
    },
    {
        path: '/systemSet/sysDic/sysDic',
        component: () => import('@/views/systemSet/sys/dic/dicList')
    },
    {
        path: '/systemSet/sysDic/dicIncrease',
        component: () => import('@/views/systemSet/sys/dic/dicIncrease')
    },
    {
        path: '/systemSet/baseData/sensitiveWords/sensitiveWords',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/baseData/sensitive/sensitiveWords')
    },
    {
        path: '/systemSet/baseData/sensitiveWords/increaseSensitive',
        component: () => import('@/views/systemSet/baseData/sensitive/increaseSensitive')
    },
    {
        path: '/systemSet/baseData/parameterConf/parameterConf',
        component: () => import('@/views/systemSet/baseData/parameterConf')
    },
    {
        path: '/systemSet/baseData/region/region',
        component: () => import('@/views/systemSet/baseData/region')
    },
    {
        path: '/systemSet/baseData/seal',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/baseData/seal')
    },
    {
        path: '/systemSet/baseData/insertSeal',
        component: () => import('@/views/systemSet/baseData/insertSeal')
    },
    {
        path: '/systemSet/baseData/clean',
        component: () => import('@/views/systemSet/baseData/clean')
    },
    {
        path: '/systemSet/baseData/initScore',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/baseData/initScore')
    },
    {
        path: '/systemSet/baseData/insertInitScore',
        component: () => import('@/views/systemSet/baseData/insertInitScore')
    },
    {
        path: '/systemSet/baseData/cashPointsRatio',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/baseData/cashPointsRatio')
    },
    {
        path: '/systemSet/baseData/xxfspz',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/baseData/xxfspz')
    },
    {
        path: '/systemSet/baseData/dxfs',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/baseData/dxfs')
    },
    {
        path: '/systemSet/baseData/familyRankListMonth',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/baseData/familyRankListMonth')
    },
    {
        path: '/systemSet/baseData/applyNumList',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/baseData/applyNumList')
    },
    {
        path: '/systemSet/baseData/scoreBalance',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/baseData/scoreBalance')
    },
    {
        path: '/systemSet/baseData/lastTimeList',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/baseData/lastTimeList')
    },
    {
        path: '/systemSet/baseData/familyRankList',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/baseData/familyRankList')
    },
    {
        path: '/systemSet/baseData/insertCashPointsRatio',
        component: () => import('@/views/systemSet/baseData/insertCashPointsRatio')
    },
    {
        path: '/systemSet/baseData/current',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/baseData/current')
    },
    {
        path: '/systemSet/baseData/insertCurrent',
        component: () => import('@/views/systemSet/baseData/insertCurrent')
    },
    {
        path: '/systemSet/baseData/giveScoreType',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/baseData/giveScoreType')
    },
    {
        path: '/systemSet/baseData/insertGiveScoreType',
        component: () => import('@/views/systemSet/baseData/insertGiveScoreType')
    },
    {
        path: '/systemSet/baseData/copyScoreType',
        component: () => import('@/views/systemSet/baseData/copyScoreType')
    },
    {
        path: '/systemSet/appMgr/appDefine/appDefine',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/appMgr/define/appDefine')
    },
    {
        path: '/systemSet/appMgr/wxdymb',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/appMgr/wxdymb')
    },
    {
        path: '/systemSet/appMgr/wxdymbInsert',
        component: () => import('@/views/systemSet/appMgr/wxdymbInsert')
    },
    {
        path: '/systemSet/appMgr/wxdymb-content',
        component: () => import('@/views/systemSet/appMgr/wxdymb-content')
    },
    {
        path: '/systemSet/appMgr/wxdynr',
        component: () => import('@/views/systemSet/appMgr/wxdynr')
    },
    {
        path: '/systemSet/appMgr/wxdynrInsert',
        component: () => import('@/views/systemSet/appMgr/wxdynrInsert')
    },
    {
        path: '/systemSet/appMgr/push',
        component: () => import('@/views/systemSet/appMgr/push')
    },
    {
        path: '/systemSet/appMgr/appDefine/increaseApplet',
        component: () => import('@/views/systemSet/appMgr/define/increaseApplet')
    },
    {
        path: '/systemSet/appMgr/appUser/appUser',
        component: () => import('@/views/systemSet/appMgr/appUser')
    },
    {
        path: '/systemSet/policy/typeList',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/policy/typeList')
    },
    {
        path: '/systemSet/policy/insertType',
        component: () => import('@/views/systemSet/policy/insertType')
    },
    {
        path: '/systemSet/policy/typeContent',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/policy/typeContent')
    },
    {
        path: '/systemSet/policy/insertTypeContent',
        component: () => import('@/views/systemSet/policy/insertTypeContent')
    },
    {
        path: '/systemSet/message/pushList',
        component: () => import('@/views/systemSet/message/pushList')
    },
    {
        path: '/systemSet/message/notice',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/systemSet/message/notice')
    },
    {
        path: '/systemSet/message/insertNotice',
        component: () => import('@/views/systemSet/message/insertNotice')
    },
    {
        path: '/systemSet/interactive/feedback',
        component: () => import('@/views/systemSet/interactive/feedback')
    },
    {
        path: '/systemSet/log/list',
        component: () => import('@/views/systemSet/log/list')
    },
    {
        path: '/systemSet/log/resetScore',
        component: () => import('@/views/systemSet/log/resetScoreList')
    },
    {
        path: '/systemSet/largeScreen/define',
        component: () => import('@/views/systemSet/largeScreen/define')
    },
    {
        path: '/systemSet/largeScreen/dpyhgl',
        component: () => import('@/views/systemSet/largeScreen/dpyhgl')
    },
    {
        path: '/systemSet/largeScreen/insertDpyhg',
        component: () => import('@/views/systemSet/largeScreen/insertDpyhg')
    },
    {
        path: '/systemSet/largeScreen/dpdlrz',
        component: () => import('@/views/systemSet/largeScreen/dpdlrz')
    }
]

export default systemRouter
import {loginOutClear, loginSuccessSet} from '@/utils/index'
import globalConfig from "@/common/globalConfig";
import axios from "axios"
const instance = axios.create({
    baseURL: globalConfig.interfaceUrl,
})
const instanceNoToken = axios.create({
    baseURL: globalConfig.interfaceUrl,
    // timeout: 3000
})
const instanceDownload = axios.create({
    baseURL: globalConfig.interfaceUrl,
    // timeout: 3000
})

const instanceRefresh = axios.create({
    baseURL: globalConfig.interfaceUrl,
})


function onRejectedResponse(error) {
    let responseData = error.response.data
    if (responseData.code === 419) {
        let userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
        let params = new FormData()
        params.append('refreshToken', userInfo.refresh_token)
        return instanceRefresh.post(`/api/auth/v1/login/refreshToken`, params).then((res2) => {
            let res = res2.data
            if (res.code === 0) {
                loginSuccessSet(res)
                return instance(error.config)
            } else if(res.code === 420) {
                if (location.hash !== '/#/login') {
                    loginOutClear()
                    window.location = window.location.origin + '/#/login'
                }
            }
        })
    } else if (responseData.code === 420) {
        if (location.hash !== '/#/login') {
            loginOutClear()
            window.location = window.location.origin + '/#/login'
        }
    }
    return Promise.reject(error);
}

// 拦截器
instance.interceptors.request.use(function (config) {
    if (!localStorage.getItem('token')) {
        if (location.hash !== '/#/login') {
            loginOutClear()
            window.location = window.location.origin + '/#/login'
        }
    }
    let userData= {
        Authorization: 'Bearer ' + localStorage.getItem('token')
    }
    config.headers = {
        ...config.headers,
        ...userData
    }
    return config;
}, function (error) {
    return Promise.reject(error);
})
instance.interceptors.response.use(function (response) {

    return response.data;

}, onRejectedResponse)

// 拦截器
instanceNoToken.interceptors.request.use(function (config) {
    let userData= {}
    config.headers = {
        ...config.headers,
        ...userData
    }
    return config;
}, function (error) {
    return Promise.reject(error);
})
instanceNoToken.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    return Promise.reject(error);
})


instanceDownload.interceptors.request.use(function (config) {
    if (!localStorage.getItem('token')) {
        if (location.hash !== '/#/login') {
            loginOutClear()
            window.location = window.location.origin + '/#/login'
        }
    }
    let userData= {
        Authorization: 'Bearer ' + localStorage.getItem('token')
    }
    config.headers = {
        ...config.headers,
        ...userData
    }
    return config;
}, function (error) {
    return Promise.reject(error);
})
instanceDownload.interceptors.response.use(function (response) {
    return response;
}, onRejectedResponse)



export {
    instance,
    instanceNoToken,
    instanceDownload
}

const residentRouter = [
    {
        path: '/resident/residentMgr/peopleInfo/familyList',
        component: () => import('@/views/systemSet/resident/peopleInfo/family/familyList')
    },
    {
        path: '/resident/residentMgr/peopleInfo/peopleInfo',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/resident/residentMgr/peopleInfo')
    },
    {
        path: '/resident/residentMgr/peopleInfo/peopleInsert',
        component: () => import('@/views/resident/residentMgr/people-insert')
    },
    {
        path: '/resident/residentMgr/peopleInfo/peopleAttestation',
        component: () => import('@/views/resident/residentMgr/peopleAttestation')
    },
    {
        path: '/resident/residentMgr/peopleInfo/sysStatistics',
        component: () => import('@/views/resident/residentMgr/sysStatistics')
    },
    {
        path: '/resident/residentMgr/peopleInfo/familyMerge',
        component: () => import('@/views/resident/residentMgr/familyMerge')
    },
    {
        path: '/resident/residentMgr/peopleInfo/peopleAudit',
        component: () => import('@/views/resident/residentMgr/people-audit')
    },
    {
        path: '/resident/residentMgr/peopleInfo/building',
        component: () => import('@/views/resident/residentMgr/building')
    },
    {
        path: '/resident/residentMgr/peopleInfo/escrow',
        component: () => import('@/views/resident/residentMgr/escrow')
    }
]

export default residentRouter
<template>
    <div class="upload-cons">
        <label v-if="loading" class="upload-btn">
            <i class="el-icon-loading"></i>
            <template v-if="!multiple && modelValue">修改{{tipsTitle}}</template>
            <template v-else>上传{{tipsTitle}}</template>
        </label>
        <label v-else class="upload-btn">
            <i class="el-icon-upload2"></i>
            <template v-if="!multiple && modelValue">修改{{tipsTitle}}</template>
            <template v-else>上传{{tipsTitle}}</template>
            <input type="file" style="display: none;" @change="onUpload">
        </label>
        <template v-if="multiple">
            <div class="img-item-cons" >
                <div class="img-item" v-for="(item, index) in modelValue" :key="index" :class="{ itemVideo: fileType === 'video' }">
                    <img v-if="fileType === 'img'" v-viewer :src="$globalUrl(item)" >
                    <video v-else-if="fileType === 'video'" controls="controls" :src="$globalUrl(item)"></video>
                    <audio v-else-if="fileType === 'audio'" :src="$globalUrl(item)"></audio>
                    <i class="el-icon-close del-item-img" @click="delItemImg(index)"></i>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="img-item-cons" v-if="modelValue">
                <div class="img-item" :class="{ itemVideo: fileType === 'video' }">
                    <img v-if="fileType === 'img'" v-viewer :src="$globalUrl(modelValue)">
                    <video v-else-if="fileType === 'video'" controls="controls" :src="$globalUrl(modelValue)"></video>
                    <audio v-else-if="fileType === 'audio'"  :src="$globalUrl(modelValue)"></audio>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { CommonApi } from "@/apis";
export default {
    props: {
        value: {
            type: [String, Array],
            default: ''
        },
        multiple: {
            type: Boolean,
            default: false
        },
        accept: {
            type: Array,
            default() {
                return []
            }
        },
        fileType: {
            type: String,
            default: 'img'
        }
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    data() {
        return {
            loading: false,
            tipsTitle: ''
        }
    },
    mounted() {
        if (this.fileType === 'img') {
            this.tipsTitle = '图片'
        } else if (this.fileType === 'video') {
            this.tipsTitle = '视频'
        } else if (this.fileType === 'audio') {
            this.tipsTitle = '音频'
        }
    },
    computed: {
        modelValue() {
            return this.value
        }
    },
    methods: {
        delAppIcon() {
            this.$emit('input', '')
        },
        onUpload(ev) {
            let files = ev.target.files[0]
            let typeArr = files.type.split('/')
            let typeStr = typeArr[typeArr.length - 1]
            if (this.accept.length > 0) {
                if (!this.accept.includes(typeStr)) {
                    let typeAccept = this.accept.join(',')
                    this.$message.error('该文件格式不支持，只支持 ' + typeAccept + ' 文件格式')
                    return
                }
            }
            let fileData = new FormData()
            fileData.append('file', files)
            let obj = {
                params: {
                    bucketName: 'base',
                    folderName: 'sccpweb'
                },
                formData: fileData
            }
            this.loading = true
            CommonApi.upload(obj).then((res) => {
                this.loading = false
                ev.target.value = ''
                if (res.code === 0) {
                    if (this.multiple) {
                        let arr = [...this.value, res.data || '']
                        this.$emit('input', arr)
                    } else {
                        this.$emit('input', res.data || '')
                    }
                }
            })
        },
        delItemImg(i) {
            this.modelValue.splice(i, 1)
            this.$emit('input', this.modelValue)
        }
    }
}
</script>
<style scoped lang="less">
    .upload-cons {
        display: flex;
    }
    .upload-btn {
        -webkit-appearance: none;
        background-color: #FFF;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        height: 38px !important;
        line-height: 38px;
        outline: 0;
        padding: 0 15px;
        transition: border-color .2s cubic-bezier(.645,.045,.355,1);
        cursor: pointer;
        margin-right: 10px;
    }
    .img-item-cons {
        display: flex;
    }
    .img-item {
        width: 38px;
        height: 38px;
        margin-right: 10px;
        cursor: pointer;
        position: relative;
        padding: 2px;
        line-height: 1;
        border: 1px solid #eeeeee;
        border-radius: 4px;
        &.itemVideo {
            width: 200px;
            height: 80px;
        }
        img {
            width: 100%;
            height: 100%;
            line-height: 1;
            border-radius: 4px;
        }
        video {
            width: 200px;
            height: 80px;
        }
        &:hover {
            .del-item-img {
                display: inline-block;
            }
        }
    }
    .del-item-img {
        position: absolute;
        font-size: 16px;
        right: -5px;
        top: -5px;
        cursor: pointer;
        color: #3055d9;
        display: none;
    }
</style>
<template>
    <el-radio-group v-model="modelValue" @change="handleChange">
        <el-radio v-for="item in sexOptions" :key="item.value" :label="item.value">
            {{item.name}}
        </el-radio>
    </el-radio-group>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        isAllItem: {
            type: Boolean,
            default: true
        }
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    data() {
        return {
            modelValue: this.value
        }
    },
    computed: {
        ...mapState({
            sexOptions: 'sexOptions'
        })
    },
    mounted() {
        if (this.sexOptions.length === 0) {
            this.getSexOptions()
        }
    },
    methods: {
        ...mapActions({
            getSexOptions: 'getSexOptions'
        }),
        handleChange(v) {
            this.$emit('input', v)
        }
    }
}
</script>
<style scoped lang="less">

</style>
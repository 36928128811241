<template>
    <el-select v-model="modelValue" placeholder="请选择组织类型" @change="handleChange">
        <el-option v-if="isAllItem" label="全部" :value="''"></el-option>
        <el-option
            v-for="item in organizationTypeOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value">
        </el-option>
    </el-select>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        isAllItem: {
            type: Boolean,
            default: true
        }
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapState({
            organizationTypeOptions: 'organizationTypeOptions'
        }),
        modelValue: {
            get(v) {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    mounted() {
        if (this.organizationTypeOptions.length === 0) {
            this.getOrganizationTypeOptions()
        }
    },
    methods: {
        ...mapActions({
            getOrganizationTypeOptions: 'getOrganizationTypeOptions'
        }),
        handleChange(v) {
            this.$emit('change', v)
        }
    }
}
</script>
<style scoped lang="less">

</style>
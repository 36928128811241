import Vue from 'vue'
import VueRouter from 'vue-router'

import appRouter from "@/router/module/app";
import govRouter from "@/router/module/gov";
import systemRouter from "@/router/module/systemSet";
import residentRouter from "@/router/module/resident";
import shopRouter from "@/router/module/shopInfo";
import statisticRouter from "@/router/module/statistic";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        component: () => import('../views/login/index')
    },
    {
        path: '/version',
        component: () => import('../views/version/index')
    },
    {
        path: '/log',
        component: () => import('../views/version/log')
    },
    {
        path: '/test',
        component: () => import('../views/version/test')
    },
    {
        path: '/workbenches',
        component: () => import('../views/workbenches'),
        children: [
            {
                path: '/home',
                component: () => import('../views/home/index')
            },
            ...appRouter,
            ...govRouter,
            ...systemRouter,
            ...residentRouter,
            ...shopRouter,
            ...statisticRouter
        ]
    }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router

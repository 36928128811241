<template>
    <el-dialog
        title="请选择菜单"
        :visible.sync="treeVisible"
        width="600px">
        <div class="top-search-cons">
            <div class="prepend-form-item">
                <div class="prepend-item-label">权限名称</div>
                <el-input v-model="filterText" class="prepend-item-input" placeholder="请输入"></el-input>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="searchFilterText">查询</el-button>
        </div>
        <div class="tree-modal-cons">
            <el-tree
                ref="menuTree"
                :data="menuTreeData"
                :props="menuTreeProps"
                :highlight-current="true"
                :show-checkbox="showCheckbox"
                node-key="id"
                :default-expand-all="defaultExpandAll"
                :filter-node-method="filterNode"
            ></el-tree>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="treeVisible = false">取 消</el-button>
            <el-button type="primary" :loading="loading" @click="selectTreeNode">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
    import { MenuApi } from "@/apis";
    export default {
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            showCheckbox: {
                type: Boolean,
                default: true
            },
            defaultExpandAll: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                menuTreeData: [],
                menuTreeProps: {
                    children: 'childMenuDto',
                    label: 'title'
                },
                treeVisible: false,
                filterText: '',
                isActivated: false
            }
        },
        mounted() {
            this.getMenuTree()
        },
        activated() {
            if (this.isActivated) {
                this.getMenuTree()
            }
        },
        deactivated() {
            this.isActivated = true
        },
        methods: {
            filterNode(value, data) {
                if (!value) return true;
                return data.title.indexOf(value) !== -1;
            },
            searchFilterText() {
                this.$refs.menuTree.filter(this.filterText);
            },
            getMenuTree() {
                MenuApi.tree().then((res) => {
                    if (res.code === 0) {
                        this.menuTreeData = res.data || []
                    }
                })
            },
            selectTreeNode() {
                if (this.showCheckbox) {
                    this.$emit('selectTree', this.$refs.menuTree.getCheckedKeys(), this.$refs.menuTree.getCheckedNodes(false, true))
                } else {
                    this.$emit('selectTree', this.$refs.menuTree.getCurrentKey(), this.$refs.menuTree.getCurrentNode())
                }
            },
            setCheckedKeys(v) {
                if (this.$refs.menuTree) {
                    if (this.showCheckbox) {
                        v.forEach((item) => {
                            let node = this.$refs.menuTree.getNode(item) || {};
                            if (node) {
                                if (node.isLeaf) {
                                    // setChecked （设置tree中对应的节点为选中状态）
                                    this.$refs.menuTree.setChecked(item, true);
                                } else {
                                    node.expand()
                                }
                            }
                        })
                    } else {
                        this.$refs.menuTree.setCurrentKey(v)
                    }
                } else {
                    this.$nextTick().then(() => {
                        if (this.showCheckbox) {
                            v.forEach((item) => {
                                let node = this.$refs.menuTree.getNode(item) || {};
                                if (node) {
                                    if (node.isLeaf) {
                                        // setChecked （设置tree中对应的节点为选中状态）
                                        this.$refs.menuTree.setChecked(item, true);
                                    } else {
                                        node.expand()
                                    }
                                }
                            })
                        } else {
                            this.$refs.menuTree.setCurrentKey(v)
                        }
                    })
                }
            },
            openModal() {
                this.treeVisible = true
            },
            closeModal() {
                this.treeVisible = false
            }
        }
    }
</script>
<style scoped lang="less">
    .tree-modal-cons {
        height: 400px;
        overflow: auto;
        margin-top: 20px;
    }
    .top-search-cons {
        display: flex;
        justify-content: space-between;
    }
    .prepend-form-item {
        display: flex;
        flex: 1;
        margin-right: 20px;
        .prepend-item-label {
            width: 100px;
            border: 1px solid #e6e6e6;
            background-color: #F3F4F6;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px 0 0 8px;
        }
        .prepend-item-input {
            flex: 1;
            overflow: hidden;
            ::v-deep {
                .el-input__inner {
                    border-radius: 0 8px 8px 0;
                }
            }
        }
    }
</style>
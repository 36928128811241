<template>
    <el-select v-model="modelValue" :disabled="disabled" placeholder="请选择小程序" @change="handleChange">
        <el-option v-if="isAllItem" label="全部" :value="''"></el-option>
        <el-option
            v-for="item in appletOptions"
            :key="item.id"
            :label="item.appName"
            :value="item.id">
        </el-option>
    </el-select>
</template>
<script>
import { AppDefineApi } from '@/apis/index'
export default {
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        isAllItem: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    data() {
        return {
            appletOptions: []
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    mounted() {
        this.getAppletOptions()
    },
    methods: {
        getAppletOptions() {
            AppDefineApi.appDefineSelect().then((res) => {
                if (res.code === 0) {
                    this.appletOptions = res.data || []
                }
            })
        },
        handleChange(v) {
            let item = this.appletOptions.find((ev) => {
                return ev.id === v
            })
            this.$emit('change', v, item || {})
        }
    }
}
</script>
<style scoped lang="less">

</style>
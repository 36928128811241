<template>
    <div class="page-detail-head">
        <span class="detail-head-back" @click="goBack">
            <i class="el-icon-arrow-left"></i>
            返回
        </span>
        <span class="detail-head-line">|</span>
        <span v-if="onType === 'add'">
                <i class="icon-head el-icon-circle-plus-outline mr10"></i>
                <template v-if="isShowTypeTitle">新增</template>{{title}}
        </span>
        <span v-else-if="onType === 'update'">
                <i class="icon-head el-icon-edit mr10"></i>
                <template v-if="isShowTypeTitle">编辑</template>{{title}}
        </span>
        <span v-else-if="onType === 'list'">
                <!--<i class="icon-head el-icon-circle-plus-outline mr10"></i>-->
                {{title}}
        </span>
        <span v-else>
                <!--<i class="icon-head el-icon-circle-plus-outline mr10"></i>-->
                {{title}}
        </span>
    </div>
</template>
<script>
    export default {
        props: {
            onType: {
                type: String,
                default: 'add'
            },
            title: {
                type: String,
                default: ''
            },
            isShowTypeTitle: {
                type: Boolean,
                default: true
            },
            customerBack: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {

            }
        },
        methods: {
            goBack() {
                if (this.customerBack) {
                    this.$emit('goBack', '')
                } else {
                    this.$router.back()
                }

            }
        }
    }
</script>
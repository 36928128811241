const shopRouter = [
    {
        path: '/shopInfo/shopMgr/companycode',
        component: () => import('@/views/shop/merchant/codeDefine')
    },
    {
        path: '/shopInfo/account/person',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/shop/account/person')
    },
    {
        path: '/shopInfo/account/personPointsDetails',
        component: () => import('@/views/shop/account/personPointsDetails')
    },
    {
        path: '/shopInfo/account/family',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/shop/account/family')
    },
    {
        path: '/shopInfo/account/familyPointsDetails',
        component: () => import('@/views/shop/account/familyPointsDetails')
    },
    {
        path: '/shopInfo/account/shop',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/shop/account/shop')
    },
    {
        path: '/shopInfo/account/shopPointsDetails',
        component: () => import('@/views/shop/account/shopPointsDetails')
    },
    {
        path: '/shopInfo/shopMgr/defineList',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/shop/merchant/defineList')
    },
    {
        path: '/shopInfo/shopMgr/staffByShop',
        component: () => import('@/views/shop/merchant/staffByShop')
    },
    {
        path: '/shopInfo/shopMgr/insertDefine',
        component: () => import('@/views/shop/merchant/insertDefine')
    },
    {
        path: '/shopInfo/shopMgr/staffList',
        component: () => import('@/views/shop/merchant/staffList')
    },
    {
        path: '/shopInfo/goods/type',
        component: () => import('@/views/shop/goods/type')
    },
    {
        path: '/shopInfo/goods/insertType',
        component: () => import('@/views/shop/goods/insertType')
    },
    {
        path: '/shopInfo/shopMgr/shopPonitSet',
        component: () => import('@/views/shop/merchant/shopPonitSet')
    },
    {
        path: '/shopInfo/shopMgr/insertShopPonitSet',
        component: () => import('@/views/shop/merchant/insertShopPonitSet')
    },
    {
        path: '/shopInfo/goods/list',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/shop/goods/list')
    },

    {
        path: '/shopInfo/goods/goodsList',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/shop/goods/goodsList')
    },
    {
        path: '/shopInfo/workflow/defined',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/shop/workflow/defined')
    },
    {
        path: '/shopInfo/workflow/workflowUser',
        component: () => import('@/views/shop/workflow/workflowUser')
    },
    {
        path: '/shopInfo/goods/insertGoods',
        component: () => import('@/views/shop/goods/insertGoods')
    },
    {
        path: '/shopInfo/order',
        component: () => import('@/views/shop/order/list')
    },
    {
        path: '/shopInfo/faq/list',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/shop/faq/list')
    },
    {
        path: '/shopInfo/faq/insertFaq',
        component: () => import('@/views/shop/faq/insertFaq')
    },

    {
        path: '/offlineStores/ddcx',
        component: () => import('@/views/offlineStores/order/list')
    },
    {
        path: '/offlineStores/spgl',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/offlineStores/goods/list')
    },
    {
        path: '/offlineStores/sgdy',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/offlineStores/merchant/defineList')
    },
    {
        path: '/offlineStores/shyg',
        component: () => import('@/views/offlineStores/merchant/staffByShop')
    },
    {
        path: '/offlineStores/splbgl',
        component: () => import('@/views/offlineStores/goods/type')
    },
    {
        path: '/offlineStores/shopMgr/insertDefine',
        component: () => import('@/views/offlineStores/merchant/insertDefine')
    },
    {
        path: '/offlineStores/goods/insertType',
        component: () => import('@/views/offlineStores/goods/insertType')
    },
    {
        path: '/offlineStores/goods/goodsList',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/offlineStores/goods/goodsList')
    },
    {
        path: '/offlineStores/goods/insertGoods',
        component: () => import('@/views/offlineStores/goods/insertGoods')
    },
]

export default shopRouter
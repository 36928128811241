import tableTool from "@/components/ui/table-tool";
import nSelect from '@/components/ui/n-select'
import upload from "@/components/ui/upload";
import richText from "@/components/ui/rich-text";

import appletSelect from "@/components/select/applet-select";
import dataTypeSelect from "@/components/select/dataType-select";
import regionSelect from "@/components/select/region-select"
import roleSelect from "@/components/select/role-select";
import sexSelect from "@/components/select/sex-select";
import statusSelect from "@/components/select/status-select";
import userTypeSelect from "@/components/select/userType-select";
import threeServiceTypeSelect from "@/components/select/threeServiceType-select";
import deptTypeSelect from "@/components/select/deptType-select";
import organizationTypeSelect from "@/components/select/organizationType-select";
import politicalOutlookSelect from "@/components/select/politicalOutlook-select";

import menuTree from "@/components/tree/menu-tree";
import regionTree from "@/components/tree/region-tree";
import topRadio from "@/components/radio-group/top-radio";
import regionUserTree from "@/components/tree/region-user-tree";
import regionGovernment from "@/components/tree/region-government";

import whetherRadio from "@/components/radio-group/whether-radio";
import sexRadio from "@/components/radio-group/sex-radio";
import statusRadio from "@/components/radio-group/status-radio";

import layoutDetailHeader from "@/components/layout/layout-detail-header";

import { isValidHttpUrl } from '@/utils/index'
import globalConfig from "@/common/globalConfig";

export default {
    install: (vue) => {
        vue.prototype.$globalUrl = function (v) {
            let host = globalConfig.imgUrl
            let str = isValidHttpUrl(v) ?  v : host + v
            return str;
        }

        /**  全局组件 **/
        vue.component('tableTool', tableTool)
        vue.component('nSelect', nSelect)
        vue.component('upload', upload)
        vue.component('richText', richText)


        vue.component('appletSelect', appletSelect)
        vue.component('dataTypeSelect', dataTypeSelect)
        vue.component('regionSelect', regionSelect)
        vue.component('roleSelect', roleSelect)
        vue.component('sexSelect', sexSelect)
        vue.component('statusSelect', statusSelect)
        vue.component('userTypeSelect', userTypeSelect)
        vue.component('threeServiceTypeSelect', threeServiceTypeSelect)
        vue.component('deptTypeSelect', deptTypeSelect)
        vue.component('organizationTypeSelect', organizationTypeSelect)
        vue.component('politicalOutlookSelect', politicalOutlookSelect)

        vue.component('menuTree', menuTree)
        vue.component('regionTree', regionTree)
        vue.component('topRadio', topRadio)
        vue.component('regionUserTree', regionUserTree)
        vue.component('regionGovernment', regionGovernment)

        vue.component('whetherRadio', whetherRadio)
        vue.component('sexRadio', sexRadio)
        vue.component('statusRadio', statusRadio)

        vue.component('layoutDetailHeader', layoutDetailHeader)
    }
}

<template>
    <div class="status-cons">
        <el-switch
            v-model="modelValue"
            :disabled="disabled"
            :active-value="1"
            :inactive-value="0"
        >
        </el-switch>
        <span class="color-main ml15" v-if="modelValue === 1">已置顶</span>
        <span class="ml15" v-if="modelValue === 0">未置顶</span>
    </div>

</template>
<script>
export default {
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        isAllItem: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
}
</script>
<style scoped lang="less">
.status-cons {
    display: flex;
    align-items: center;
}
</style>